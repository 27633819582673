/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const ServiceSchema = ({ page, settings }) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          logoUrl
          siteUrl
        }
      }
    }
  `).site.siteMetadata;

  const { meta_image, meta_title, meta_description } = page.data;

  const { logo, site_title } = settings.data;

  const pageUrl = new URL(page.url, config.siteUrl).toString();

  return (
    <Helmet>
      <script type="application/ld+json">{`{
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "${meta_title}",
        "description": "${meta_description}",
        "provider": {
          "@type": "Organization",
          "name": "${site_title}",
          "url": "${pageUrl}",
          "logo": "${logo.url}"
        },
        "areaServed": {
          "@type": "Place",
          "name": "Greece"
        },
        "offers": {
          "@type": "Offer",
          "url": "${pageUrl}",
          "price": "0.00",
          "priceCurrency": "EUR",
          "isAccessibleForFree": "True",
          "eligibleRegion": {
            "@type": "Place",
            "name": "Greece"
          }
        }
      }`}</script>
    </Helmet>
  );
};

export default ServiceSchema;
